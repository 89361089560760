import "./howithelps.css"
const HowItHelps = () => {
    return (
        <div className="col-md-12 mt-4">
            <div className="row how-it-works-container">
                <div>
                    <div className="col-md-12">
                        <div className="sub-div-headings d-flex mt-2 text-center justify-content-center ">
                            <span>
                                How the{" "}
                                <span className="span-with-violet">
                                    AML Process Accelerator{" "}
                                </span>{" "}
                                Helps Banks
                            </span>
                        </div>
                        <div className="d-flex justify-content-center">
                            <div>
                                <span className="text-center span-with-violet"></span>
                            </div>
                            <span className="text-center">
                                Here’s how the AML Process By integrating  RadiantWork Technology Services ’s AML
                                Process Accelerator into their compliance systems, banks can
                                realize a range of benefits that improve both operational
                                efficiency and customer satisfaction:
                            </span>
                        </div>
                    </div>
                </div>
                <div className="col-12 pb-4">
                    <div className="row d-flex justify-content-center">
                        <div className="col-md-3 mt-5 custom-width-steps-div">
                            <div className="homePage-how-it-help-card">
                                <div className="homePage-how-it-help-card-body pb-3">
                                    <div className="pt-3">
                                        <div className="d-flex justify-content-center">
                                            <span className="text-center span-with-violet inner-text-heading-two">Reduce
                                                Compliance Costs
                                            </span>
                                        </div>
                                        <div className="inner-text-content-three text-center mt-2 p-3">
                                            Manual reviews of false positives are a major expense for bank compliance departments. The <span className="span-with-violet">AML Process Accelerator </span>reduces this burden by accurately identifying true risks, cutting flagged transactions needing manual intervention by up to 80%, and enabling more efficient resource allocation.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mt-5 custom-width-steps-div">
                            <div className="homePage-how-it-help-card">
                                <div className="homePage-how-it-help-card-body pb-3">
                                    <div className="pt-3">
                                        <div className="d-flex justify-content-center">
                                            <span className="text-center inner-text-heading-two span-with-violet">Improve Accuracy and Speed</span>
                                        </div>
                                        <div className="inner-text-content-three text-center mt-2 p-3">
                                            Through <span className="span-with-violet">real-time fraud detection</span>, our solution enhances the accuracy of AML systems, ensuring that high-risk transactions are identified faster and with greater precision. This not only ensures compliance but also prevents potential threats from slipping through the cracks.

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mt-5 custom-width-steps-div">
                            <div className="homePage-how-it-help-card">
                                <div className="homePage-how-it-help-card-body pb-3">
                                    <div className="pt-3">
                                        <div className="d-flex justify-content-center">
                                            <span className="text-center inner-text-heading-two span-with-violet">Explainable AI for Transparency</span>
                                        </div>
                                        <div className="inner-text-content-three text-center mt-2 p-3">
                                            Our solution is built with <span className="span-with-violet">Explainable AI</span> , offering detailed insights into why decisions were made by the system. This transparency ensures that compliance teams trust the system's results, while also satisfying regulatory bodies’ demands for clear audit trails in AML decision-making.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mt-5 custom-width-steps-div">
                            <div className="homePage-how-it-help-card">
                                <div className="homePage-how-it-help-card-body pb-3">
                                    <div className="pt-3">
                                        <div className="d-flex justify-content-center">
                                            <span className="text-center inner-text-heading-two span-with-violet">Enhanced Customer Satisfaction</span>
                                        </div>
                                        <div className="inner-text-content-three text-center mt-2 p-3">
                                            By reducing delays caused by false positives and unnecessary transaction holds, banks can improve the overall experience for their customers. The <span className="span-with-violet"> AML Process Accelerator </span>ensures smooth transaction flows for genuine clients, enhancing trust and satisfaction in the bank’s services.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HowItHelps;
