// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-custom-no-bg {
  border: none !important;
}

.card-custom-homepage {
  height: 100% !important;
}

.div-height-homepage {
  height: 100% !important;
}

.card-body-custom {
  padding-left: 0 !important;
}

.relative-div-bg {
  position: relative;
}
.absolute-div-home {
  position: absolute;
  top: 0px;
  right: 0;
  height: 200px;
  width: 50% !important;
  /* background-color: red; */
  z-index: -1;
  padding: 0;
}

 

.absolute-img-div-leaf-choos {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}
.img-leaf-choos {
  transform: rotate(80deg) scaleX(-1);
}


.inner-text-heading {
  font-weight: bold;
  margin-bottom: 10px;
}

.sub-div-headings-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}

@media (max-width: 600px) {
  .absolute-side-div-img {
    display: none;
  }
  .absolute-home-div-img {
right: 50%;  }
  .absolute-div-home {
    /* display: none; */
    width: 200px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Home/homepage.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;AACzB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,QAAQ;EACR,QAAQ;EACR,aAAa;EACb,qBAAqB;EACrB,2BAA2B;EAC3B,WAAW;EACX,UAAU;AACZ;;;;AAIA;EACE,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,WAAW;AACb;AACA;EACE,mCAAmC;AACrC;;;AAGA;EACE,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE;IACE,aAAa;EACf;EACA;AACF,UAAU,GAAG;EACX;IACE,mBAAmB;IACnB,YAAY;EACd;AACF","sourcesContent":[".card-custom-no-bg {\n  border: none !important;\n}\n\n.card-custom-homepage {\n  height: 100% !important;\n}\n\n.div-height-homepage {\n  height: 100% !important;\n}\n\n.card-body-custom {\n  padding-left: 0 !important;\n}\n\n.relative-div-bg {\n  position: relative;\n}\n.absolute-div-home {\n  position: absolute;\n  top: 0px;\n  right: 0;\n  height: 200px;\n  width: 50% !important;\n  /* background-color: red; */\n  z-index: -1;\n  padding: 0;\n}\n\n \n\n.absolute-img-div-leaf-choos {\n  position: absolute;\n  bottom: 0;\n  left: 0;\n  z-index: -1;\n}\n.img-leaf-choos {\n  transform: rotate(80deg) scaleX(-1);\n}\n\n\n.inner-text-heading {\n  font-weight: bold;\n  margin-bottom: 10px;\n}\n\n.sub-div-headings-2 {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-top: 2rem;\n}\n\n@media (max-width: 600px) {\n  .absolute-side-div-img {\n    display: none;\n  }\n  .absolute-home-div-img {\nright: 50%;  }\n  .absolute-div-home {\n    /* display: none; */\n    width: 200px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
