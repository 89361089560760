// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.how-it-works-container{
   background-color: #ffffff;
}
.homePage-how-it-help-card{
  border-top: 5px solid #4733C9;
  height: 100% !important;
  width: 100% !important;
  display: block;
  padding-top: 0%;
  padding: 0px;
  border-radius: 0px;
  border-bottom-right-radius: 50px;
  background-color: #ffffff;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.2);

}
.homePage-how-it-help-card:hover{
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.homePage-how-it-help-card-body {
  padding: 0px !important;

}`, "",{"version":3,"sources":["webpack://./src/components/HowItWorksComponents/howithelps.css"],"names":[],"mappings":"AAAA;GACG,yBAAyB;AAC5B;AACA;EACE,6BAA6B;EAC7B,uBAAuB;EACvB,sBAAsB;EACtB,cAAc;EACd,eAAe;EACf,YAAY;EACZ,kBAAkB;EAClB,gCAAgC;EAChC,yBAAyB;EACzB,2CAA2C;;AAE7C;AACA;EACE,4DAA4D;EAC5D,2CAA2C;EAC3C,eAAe;AACjB;AACA;EACE,uBAAuB;;AAEzB","sourcesContent":[".how-it-works-container{\n   background-color: #ffffff;\n}\n.homePage-how-it-help-card{\n  border-top: 5px solid #4733C9;\n  height: 100% !important;\n  width: 100% !important;\n  display: block;\n  padding-top: 0%;\n  padding: 0px;\n  border-radius: 0px;\n  border-bottom-right-radius: 50px;\n  background-color: #ffffff;\n  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.2);\n\n}\n.homePage-how-it-help-card:hover{\n  transition: background-color 0.3s ease, box-shadow 0.3s ease;\n  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.2);\n  cursor: pointer;\n}\n.homePage-how-it-help-card-body {\n  padding: 0px !important;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
