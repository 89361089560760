// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-us-container{
    border: 2px solid #4733C9;
    border-radius: 10px;
    /* padding: 0px !important; */
}
.about-us-content-container{
    background-color: #F0F3FF;
    border-radius: 20px;
}`, "",{"version":3,"sources":["webpack://./src/pages/AboutUs/aboutUs.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,6BAA6B;AACjC;AACA;IACI,yBAAyB;IACzB,mBAAmB;AACvB","sourcesContent":[".about-us-container{\n    border: 2px solid #4733C9;\n    border-radius: 10px;\n    /* padding: 0px !important; */\n}\n.about-us-content-container{\n    background-color: #F0F3FF;\n    border-radius: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
