import { useEffect, useState } from "react";

// import { Carousel } from "react-bootstrap";
import "./homepagecarousel.css";
import { carouselImage } from "../../assets";
const HomepageCarousel = () => {
  return (
    <div
      id="carouselExampleControls"
      className="carousel slide homepage-carousel"
      data-bs-ride="carousel"
    >
      <div className="carousel-inner p-3 mt-5">
        <div className="carousel-item active">
          <div className="row">
            <div className="col-md-12">
              <div className="row carousel-div-home">
                <div className="col-md-8 custom-text-center p-5">
                  <div className="carousel-inner-text-2 pt-4">
                    <h4>
                      <span>Revolutionizing </span>
                    </h4>
                    <span> <h3>AML with </h3></span>
                    <span>
                      <h1><u> AI-Powered Solutions</u></h1>
                    </span>
                    <span>Automate your Compliance Processes with our best-in-class AI-powered AML Solutions </span>
                  </div>
                  <div className="pb-3 pt-5">
                    <button className="violet-button-style">Learn More</button>
                  </div>
                </div>
                <div className="col-md-4 d-flex justify-content-center align-items-center p-2">
                  <img
                    src={carouselImage}
                    alt=""
                    className="img-fluid  responsive-img "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomepageCarousel;
