import { useEffect, useState } from "react";
// import { Footer, NavbarTop } from "../../components";
import { Outlet } from "react-router-dom";
import "../css/styles.css";
import HeaderComponent from "../../components/HeaderComponent";
import FooterComponent from "../../components/FooterComponent";
import DemoComponent from "../../components/DemoComponent";

const HomeIndex = () => {
    const [currentPage, setCurrentPage] = useState("Home");

    return (
        <>
            <HeaderComponent currentPage={currentPage}
                setCurrentPage={setCurrentPage} />
            <div className="col-12 d-flex justify-content-center">
                <div className="width-full-page relative-div-bg">
                    <div className="absolute-div-home">
                        <div className="absolute-home-div-img" />
                    </div>
                </div>
            </div>
            <div className="min-height"><Outlet /></div>
            <FooterComponent currentPage={currentPage} setCurrentPage={setCurrentPage} />
        </>
    );
};

export default HomeIndex;
