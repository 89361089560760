import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomeIndex from "../pages/HomeIndex";
import HomePage from "../pages/Home";
import AboutUs from "../pages/AboutUs";
import RequestDemo from "../pages/RequestDemo";
import PrivacyPolicy from "../pages/PrivacyPolicy";

const RouterComponent: React.FC = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<HomeIndex />}>
                    <Route index element={<HomePage />} />
                    <Route path="/AboutUs" element={<AboutUs />} />
                    <Route path="/RequestDemo" element={<RequestDemo />} />
                    <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
                </Route>
            </Routes>
        </Router>
    );
};

export default RouterComponent;
