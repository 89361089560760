// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product-section-container-type-one{
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.2);
  background-color: #F0F3FF;
  border-radius: 10px;
}
.product-section-container-type-two{
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
.product-section-container-type-one:hover{
box-shadow: -5px 4px 31px -12px rgba(0,0,0,5);
}
.product-section-container-type-two:hover{
box-shadow: -5px 4px 31px -12px rgba(0,0,0,5);
}`, "",{"version":3,"sources":["webpack://./src/components/ProductComponent/productComponent.css"],"names":[],"mappings":"AAAA;EACE,2CAA2C;EAC3C,yBAAyB;EACzB,mBAAmB;AACrB;AACA;EACE,2CAA2C;EAC3C,kBAAkB;AACpB;AACA;AACA,6CAA6C;AAC7C;AACA;AACA,6CAA6C;AAC7C","sourcesContent":[".product-section-container-type-one{\n  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.2);\n  background-color: #F0F3FF;\n  border-radius: 10px;\n}\n.product-section-container-type-two{\n  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.2);\n  border-radius: 5px;\n}\n.product-section-container-type-one:hover{\nbox-shadow: -5px 4px 31px -12px rgba(0,0,0,5);\n}\n.product-section-container-type-two:hover{\nbox-shadow: -5px 4px 31px -12px rgba(0,0,0,5);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
