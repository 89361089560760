import React from "react";

const PrivacyPolicy = () => {
    return (
        <>
            <div className="col-12 d-flex justify-content-center text-start pb-4">
                <div className="row width-full-page pb-3">
                    <div className="col-md-12 text-center">
                        <h4 className="heading-pages pt-5">
                            <span className="span-with-violet">Privacy</span> Policy
                        </h4>
                    </div>
                    <div className="col-md-12">
                        <div className="sub-div-headings pt-4">Introduction</div>
                        <div className="inner-text-content-two pt-3">
                            Radiant Technologies (referred to as "Radiant," "we," "our," or
                            "us") is dedicated to safeguarding the privacy and security of
                            your personal information. This Privacy Policy outlines how we
                            collect, use, disclose, and protect your personal information when
                            you use our services, including our website and applications.
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="sub-div-headings pt-4">Information We Collect</div>
                        <div className="inner-text-content-two pt-3">
                            We collect various types of information, including but not limited
                            to:
                        </div>
                        <ul className="pt-3">

                            <li>
                                <span className="inner-text-heading-two">Location Information:</span> We may collect your device's location data to provide our services effectively.
                            </li>

                            <li>
                                <span className="inner-text-heading-two">Usage Information:</span> Data on how you interact with our website and applications.
                            </li>
                            <li>
                                <span className="inner-text-heading-two">Device Information:</span> Details about the devices you use to access our services, such as hardware, operating systems, and software.
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-12">
                        <div className="sub-div-headings pt-4">How We Use Your Information</div>
                        <div className="inner-text-content-two pt-3">
                            We use your information for the following purposes:
                        </div>
                        <ul className="pt-3">
                            <li>
                                <span className="inner-text-heading-two">To process and manage</span> your requests and services.
                            </li>
                            <li>
                                <span className="inner-text-heading-two">To communicate</span> with you regarding our services and provide support.
                            </li>
                            <li>
                                <span className="inner-text-heading-two">To enhance and customize</span> your experience with our services.
                            </li>
                            <li>
                                <span className="inner-text-heading-two">To send you updates and offers</span> related to our services.
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-12">
                        <div className="sub-div-headings pt-4">Information Sharing</div>
                        <div className="inner-text-content-two pt-3">
                            We may share your information with:
                        </div>
                        <ul className="pt-3">
                            <li>
                                <span className="inner-text-heading-two">Service Providers:</span> Third-party entities that assist us with various functions, such as payment processing and analytics.
                            </li>
                            <li>
                                <span className="inner-text-heading-two">Partners:</span> Business partners with whom we collaborate to provide services.
                            </li>
                            <li>
                                <span className="inner-text-heading-two">Legal Requirements:</span> Disclosure as required to comply with laws or respond to legal requests.
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-12">
                        <div className="sub-div-headings pt-4">Data Security</div>
                        <div className="inner-text-content-two pt-3">
                            We implement robust security measures to protect your information. However, no data transmission over the internet or storage system can be guaranteed to be 100% secure.
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="sub-div-headings pt-4">Your Choices</div>
                        <div className="inner-text-content-two pt-3">You have the following rights:</div>
                        <ul className="pt-3">
                            <li>
                                <span className="inner-text-heading-two">Access and Update:</span> Review and modify your personal information through your account settings.
                            </li>
                            <li>
                                <span className="inner-text-heading-two">Opt-Out:</span> Opt out of marketing communications at any time.
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-12">
                        <div className="sub-div-headings pt-4">Cookies and Tracking Technologies</div>
                        <div className="inner-text-content-two pt-3">
                            We use cookies and similar technologies to improve your experience. You can adjust your cookie preferences through your browser settings.
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="sub-div-headings pt-4">Contact Us</div>
                        <div className="inner-text-content-two pt-3">
                            For any questions or concerns regarding this Privacy Policy, please contact us at:&nbsp;
                            <a href="mailto:support@radianttech.com" className="span-with-violet-mail">
                                sajith.mohammed@iconnect101.com
                            </a>
                        </div>
                    </div>
                    <div className="col-md-12 pb-4">
                        <div className="sub-div-headings pt-4">Consent</div>
                        <div className="inner-text-content-two pt-3">
                            By using our services, you consent to the terms outlined in this Privacy Policy.
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PrivacyPolicy;
