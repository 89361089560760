import carouselImage from "./carouselImage.png"
import step1Image from "./step 1.png";
import step2Image from "./step 2.png";
import step3Image from "./step 3.png";
import step4Image from "./step 4.png";
import step5Image from "./step 5.png";
import homeNavHamburgerImg from "./home-nav-hamburger-img.svg";
import productSectionImg from "./product-secion-image.png";
import aboutUsImg from "./about-us-img.png";
import radiantlogo from "./radiantlogo.png"
export {
    carouselImage,
    step1Image,
    step2Image,
    step3Image,
    step4Image,
    step5Image,
    homeNavHamburgerImg,
    productSectionImg,
    aboutUsImg,
    radiantlogo,
};
