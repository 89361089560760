// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.demo-container {
    background-color: #4733C9;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.demo-button-bottom {
    background-color: #BDF8FF;
    color: #4733C9;
    border: none;
    padding: 10px 20px;
    margin-top: 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.demo-button-bottom:hover {
    background-color: #acd7db;
    color: #4733C9;
}
`, "",{"version":3,"sources":["webpack://./src/components/DemoComponent/democomponent.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,cAAc;IACd,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;IAChB,kBAAkB;IAClB,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,cAAc;AAClB","sourcesContent":[".demo-container {\n    background-color: #4733C9;\n    color: #FFFFFF;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    text-align: center;\n}\n\n.demo-button-bottom {\n    background-color: #BDF8FF;\n    color: #4733C9;\n    border: none;\n    padding: 10px 20px;\n    margin-top: 20px;\n    border-radius: 5px;\n    cursor: pointer;\n    font-size: 16px;\n}\n\n.demo-button-bottom:hover {\n    background-color: #acd7db;\n    color: #4733C9;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
