import { Card, CardBody } from "react-bootstrap";
import {
    step1Image,
    step2Image,
    step3Image,
    step4Image,
    step5Image,
} from "../../assets";
import "./homePageSteps.css";
const HomePageSteps = () => {
    return (
        <div className="col-md-12 mt-4">
            <div className="row steps-container">
                <div>
                    <div className="col-md-12">
                        <div className="sub-div-headings d-flex mt-2 justify-content-center ">
                            <span>How<span className="span-with-violet"> It Works</span></span>
                        </div>
                        <div className="d-flex justify-content-center"><span>Here’s how the AML Process Accelerator seamlessly integrates with your existing AML screening systems to enhance compliance operations.</span></div>
                    </div>
                </div>
                <div className="col-12 pb-4">
                    <div className="row d-flex justify-content-center">
                        <div className="col-md-2 inner-text-content-three mt-5 custom-width-steps-div">
                            <Card className="homePage-service-card">
                                <CardBody className="homePage-service-card-body pb-3">
                                    <div className="pt-3">
                                        <div className="d-flex justify-content-center">
                                            <img className="homePage-step-image img-fluid" src={step1Image} />
                                        </div>
                                        <div className="inner-text-content-two text-center mt-2 p-3">
                                            Suspect transactions are automatically extracted from your bank's AML system and sent to the <span className="span-with-violet">AML Process Accelerator.</span>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                        <div className="col-md-2 inner-text-content-three mt-5 custom-width-steps-div">
                            <Card className="homePage-service-card">
                                <CardBody className="homePage-service-card-body pb-3">
                                    <div className="pt-3">
                                        <div className="d-flex justify-content-center">
                                            <img className="homePage-step-image img-fluid" src={step2Image} />
                                        </div>
                                        <div className="inner-text-content-two text-center mt-2 p-3">
                                            The <span className="span-with-violet">AI engine</span>  evaluates each transaction based on historical patterns, customer profiles, and other relevant data points.
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                        <div className="col-md-2 inner-text-content-three mt-5 custom-width-steps-div">
                            <Card className="homePage-service-card">
                                <CardBody className="homePage-service-card-body pb-3">
                                    <div className="pt-3">
                                        <div className="d-flex justify-content-center">
                                            <img className="homePage-step-image img-fluid" src={step3Image} />
                                        </div>
                                        <div className="inner-text-content-two text-center mt-2 p-3">
                                            The system classifies the transaction as either <span className="span-with-violet">clean </span>or <span className="span-with-violet">confirmed</span>, drastically reducing the number of false positives.
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                        <div className="col-md-2 inner-text-content-three mt-5 custom-width-steps-div">
                            <Card className="homePage-service-card">
                                <CardBody className="homePage-service-card-body pb-3">
                                    <div className="pt-3">
                                        <div className="d-flex justify-content-center">
                                            <img className="homePage-step-image img-fluid" src={step4Image} />
                                        </div>
                                        <div className="inner-text-content-two text-center mt-2 p-3">
                                            Every decision made by the AI is fully explainable, ensuring <span className="span-with-violet">transparency</span> and compliance with regulatory requirements.
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                        <div className="col-md-2 inner-text-content-three mt-5 custom-width-steps-div">
                            <Card className="homePage-service-card">
                                <CardBody className="homePage-service-card-body pb-3">
                                    <div className="pt-3">
                                        <div className="d-flex justify-content-center">
                                            <img className="homePage-step-image img-fluid" src={step5Image} />
                                        </div>
                                        <div className="inner-text-content-two text-center mt-2 p-3">
                                            The compliance team only reviews high-priority cases, improving <span className="span-with-violet">efficiency</span> and focusing efforts on real risks.
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default HomePageSteps;
