// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.homePage-service-card {
  border: none !important;
  height: 100% !important;
  width: 100% !important;
  display: block;
  padding-top: 0%;
  padding: 0px;
  border-radius: 10px;
  background-color: #F0F3FF;
}
.homePage-service-card:hover {
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.steps-container{
  background-color: #ffffff;
}
.homePage-service-card-body {
  padding: 0px !important;
}
.homePage-step-image{
  width: 100px;
  height: auto;
  border-radius: 5px;
}

.custom-width-steps-div {
  min-width: 225px;
  position: relative;
}

.line-step-show {
  position: absolute;
  top: 55px;
  left: 175px;
  z-index: 2;
  width: 90px;
}
.line-div-max-length-step {
  width: 100%;
  border-bottom: 1.5px dotted #4733C9;
  position: absolute;
  bottom: 7px;
  max-width: 150px;
}

@media (max-width: 1100px) {
  .line-step-show {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/StepsComponent/homePageSteps.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,uBAAuB;EACvB,sBAAsB;EACtB,cAAc;EACd,eAAe;EACf,YAAY;EACZ,mBAAmB;EACnB,yBAAyB;AAC3B;AACA;EACE,4DAA4D;EAC5D,2CAA2C;EAC3C,eAAe;AACjB;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,uBAAuB;AACzB;AACA;EACE,YAAY;EACZ,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,UAAU;EACV,WAAW;AACb;AACA;EACE,WAAW;EACX,mCAAmC;EACnC,kBAAkB;EAClB,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[".homePage-service-card {\n  border: none !important;\n  height: 100% !important;\n  width: 100% !important;\n  display: block;\n  padding-top: 0%;\n  padding: 0px;\n  border-radius: 10px;\n  background-color: #F0F3FF;\n}\n.homePage-service-card:hover {\n  transition: background-color 0.3s ease, box-shadow 0.3s ease;\n  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.2);\n  cursor: pointer;\n}\n.steps-container{\n  background-color: #ffffff;\n}\n.homePage-service-card-body {\n  padding: 0px !important;\n}\n.homePage-step-image{\n  width: 100px;\n  height: auto;\n  border-radius: 5px;\n}\n\n.custom-width-steps-div {\n  min-width: 225px;\n  position: relative;\n}\n\n.line-step-show {\n  position: absolute;\n  top: 55px;\n  left: 175px;\n  z-index: 2;\n  width: 90px;\n}\n.line-div-max-length-step {\n  width: 100%;\n  border-bottom: 1.5px dotted #4733C9;\n  position: absolute;\n  bottom: 7px;\n  max-width: 150px;\n}\n\n@media (max-width: 1100px) {\n  .line-step-show {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
