import { Link, useNavigate } from "react-router-dom";
import { homeNavHamburgerImg, radiantlogo } from "../../assets";
import "./headerComponent.css";
import { useEffect, useState } from "react";

const datanavbottom = [
    {
        label: "Home",
        value: "Home",
        navlink: "/",
    },
    {
        label: "About us",
        value: "Aboutus",
        navlink: "/AboutUs",
    },
];

interface NavBarTopProps {
    currentPage: string;
    setCurrentPage: (page: string) => void;
}

const HeaderComponent: React.FC<NavBarTopProps> = ({ currentPage, setCurrentPage }) => {
    const [selectedNav, setselectedNav] = useState("Home");
    const navigate = useNavigate();  
    const handleSetActiveNavigation = (nav: string, path: string) => {
        setselectedNav(nav);
        setCurrentPage(nav);
        navigate(path); 
    };

    useEffect(() => {
        setselectedNav(currentPage);
    }, [currentPage]);

    return (
        <>
            <nav
                className="custom-navbar-header navbar navbar navbar-expand-md pb-0 pt-3"
                aria-label="Furni navigation bar"
            >
                <div className="container pb-0">
                    <div className="d-flex align-items-center">
                        <img src={radiantlogo} className="logo-main-radiant"/>
                             <a className="navbar-brand navbar-brand-custom" href="#">
                        RadiantWork Technology Services  
                    </a> 
                   </div>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarsradiant"
                        aria-controls="navbarsradiant"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <img src={homeNavHamburgerImg} alt="" className="img-fluid" />
                    </button>

                    <div className="collapse navbar-collapse pb-0" id="navbarsradiant">
                        <ul className="custom-navbar-header-nav navbar-nav ms-auto mb-2 mb-md-0">
                            {datanavbottom.map((item) => (
                                <li key={item.value}>
                                    <Link
                                        className="nav-link pb-4"
                                        to={item.navlink}
                                        onClick={() => handleSetActiveNavigation(item.value, item.navlink)}
                                    >
                                        {item.label}
                                    </Link>
                                </li>
                            ))}
                            <li>
                                <button
                                    className="violet-button-style"
                                    onClick={() => handleSetActiveNavigation("RequestDemo", "/RequestDemo")}
                                >
                                    Request A Demo
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
};

export default HeaderComponent;
