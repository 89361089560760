import { productSectionImg } from "../../assets";
import "./productComponent.css";
const ProductComponent = () => {

    return (
        <div className="col-md-12 mt-5">
            <div className="row how-it-works-container">
                <div className="col-md-12">
                    <div className="d-flex justify-content-center">
                        <span className="text-center inner-text-content">
                            Our Product
                        </span>
                    </div>
                    <div className="sub-div-headings d-flex mt-2 justify-content-center ">
                        <span className="span-with-violet">
                            AML Process Accelerator
                        </span>
                    </div>
                </div>
                <div className="col-12">
                    <div className="row mt-5">
                        <div className="col-md-6 text-center">
                            <img src={productSectionImg} alt="" className="img-fluid responsive-img" />
                        </div>
                        <div className="col-md-6">
                            <div className="product-section-container-type-one p-3 text-start">
                                <span className="inner-text-heading">
                                    Revolutionizing <span className="span-with-violet" >AML</span> Compliance
                                </span>
                                <div>
                                    <span className="inner-text-content-three text-start">
                                         RadiantWork Technology Services 's AML Process Accelerator transforms traditional AML compliance workflows, addressing inefficiencies caused by the high volume of flagged transactions and false positives. These false positives drive up costs and delay the detection of real threats.
                                    </span>
                                </div>
                            </div>
                            <div className="product-section-container-type-one p-3 mt-4 text-start">
                                <span className="inner-text-heading">
                                    Our   <span className="span-with-violet" >Solution:</span>
                                </span>
                                <div>
                                    <span className="inner-text-content-three text-start">
                                        The AML Process Accelerator tackles these challenges head-on using advanced machine learning models. It analyzes flagged cases in real time, accurately distinguishing between legitimate transactions and real threats. By prioritizing the right cases, the system significantly reduces unnecessary manual reviews, enhancing the efficiency of compliance teams and streamlining operations.
                                    </span>
                                </div>
                            </div>
                            <div className="product-section-container-type-two inner-text-content-three p-2 mt-3">
                                <span><span className="span-with-violet">Reduce False Positives:</span>Advanced AI models filter out false positives, cutting down the number of unnecessary reviews.</span>
                            </div>
                            <div className="product-section-container-type-two inner-text-content-three p-2 mt-3">
                                <span><span className="span-with-violet">Real-Time Analysis: </span>Ensures rapid detection and prioritization of genuine threats, improving response times.</span>
                            </div>
                            <div className="product-section-container-type-two inner-text-content-three p-2 mt-3">
                                <span><span className="span-with-violet">Cost Efficiency:</span> Lower operational costs by minimizing manual intervention, allowing teams to focus on critical cases.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
};

export default ProductComponent;
