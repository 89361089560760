 
import './App.css';
import "./pages/css/styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import RouterComponent from './routes';

function App() {
  return (
    <>
      <RouterComponent />
    </>
  );
}

export default App;
