import "./requestdemo.css";

const RequestDemo = () => {
    const handleClick = () => {
        window.location.href = "mailto:sajith.mohammed@iconnect101.com.";
    };

    return (
        <div className="col-md-12 mt-4">
            <div>
                <div className="col-md-12">
                    <div className="sub-div-headings d-flex mt-2 justify-content-center">
                        <span>Request A
                            <span className="span-with-violet"> Demo</span>
                        </span>
                    </div>
                </div>
            </div>
            <div className="demo-req-box">
                <p className="demo-text">
                    Interested in seeing how our solution can help you? Get a personalized demo to experience the full capabilities of our product and how it fits your needs. Our team is ready to walk you through the features and answer any questions you may have.
                </p>
                <h2 className="demo-features-title">What to expect in the demo:</h2>
                <ul className="demo-features-list">
                    <li>A personalized walkthrough of our key features</li>
                    <li>A live look at how our product solves your business challenges</li>
                    <li>Answers to your specific questions and concerns</li>
                    <li>Insights into how you can maximize the benefits of our solution</li>
                </ul>
                <button className="request-demo-btn" onClick={handleClick}>
                    Request a Demo
                </button>
            </div>
        </div>
    );
};

export default RequestDemo;
