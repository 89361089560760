import HomepageCarousel from "../../components/CarouselComponent";
import DemoComponent from "../../components/DemoComponent";
import HowItHelps from "../../components/HowItWorksComponents";
import ProductComponent from "../../components/ProductComponent";
import HomePageSteps from "../../components/StepsComponent";
import "./homepage.css";


const HomePage = () => {

    return (
        <>
            <div className="col-12 d-flex justify-content-center text-start pb-4">
                <div className="row width-full-page pb-5 ">
                    <div className="col-md-12">
                        <HomepageCarousel />
                        <ProductComponent />
                        <HowItHelps />
                        <HomePageSteps />
                    </div>
                </div>
            </div>
            <DemoComponent />

        </>
    );
};

export default HomePage;
