// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.demo-req-box {
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 40px;
  margin: 20px auto;
  max-width: 800px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}
.demo-req-box:hover {
  box-shadow: -5px 4px 31px -12px rgba(0, 0, 0, 5);
}

.demo-text {
  font-size: 18px;
  color: #555;
  line-height: 1.6;
  margin-bottom: 30px;
  text-align: center;
}

.demo-features-title {
  font-size: 24px;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.demo-features-list {
  list-style-type: none;
  padding: 0;
  font-size: 18px;
  color: #555;
  line-height: 1.8;
  margin-bottom: 40px;
  text-align: center;
}

.demo-features-list li {
  margin-bottom: 10px;
}

.request-demo-btn {
  display: inline-block;
  background-color: #4733c9;
  color: white;
  padding: 15px 30px;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
  margin: 0 auto;
  display: block;
}

.request-demo-btn:hover {
  background-color: #4733c9;
}
`, "",{"version":3,"sources":["webpack://./src/pages/RequestDemo/requestdemo.css"],"names":[],"mappings":"AAAA;EACE,2CAA2C;EAC3C,kBAAkB;EAClB,yBAAyB;EACzB,mBAAmB;EACnB,aAAa;EACb,iBAAiB;EACjB,gBAAgB;EAChB,0CAA0C;AAC5C;AACA;EACE,gDAAgD;AAClD;;AAEA;EACE,eAAe;EACf,WAAW;EACX,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,WAAW;EACX,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,UAAU;EACV,eAAe;EACf,WAAW;EACX,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,0CAA0C;EAC1C,sCAAsC;EACtC,cAAc;EACd,cAAc;AAChB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".demo-req-box {\n  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.2);\n  border-radius: 5px;\n  background-color: #f9f9f9;\n  border-radius: 10px;\n  padding: 40px;\n  margin: 20px auto;\n  max-width: 800px;\n  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);\n}\n.demo-req-box:hover {\n  box-shadow: -5px 4px 31px -12px rgba(0, 0, 0, 5);\n}\n\n.demo-text {\n  font-size: 18px;\n  color: #555;\n  line-height: 1.6;\n  margin-bottom: 30px;\n  text-align: center;\n}\n\n.demo-features-title {\n  font-size: 24px;\n  color: #333;\n  text-align: center;\n  margin-bottom: 20px;\n}\n\n.demo-features-list {\n  list-style-type: none;\n  padding: 0;\n  font-size: 18px;\n  color: #555;\n  line-height: 1.8;\n  margin-bottom: 40px;\n  text-align: center;\n}\n\n.demo-features-list li {\n  margin-bottom: 10px;\n}\n\n.request-demo-btn {\n  display: inline-block;\n  background-color: #4733c9;\n  color: white;\n  padding: 15px 30px;\n  font-size: 18px;\n  border: none;\n  border-radius: 5px;\n  cursor: pointer;\n  text-align: center;\n  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);\n  transition: background-color 0.3s ease;\n  margin: 0 auto;\n  display: block;\n}\n\n.request-demo-btn:hover {\n  background-color: #4733c9;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
